import Dog1 from '../../assets/PetImages/Matches/dog01.jpg';
import Dog2 from '../../assets/PetImages/Matches/dog02.jpg';
import Dog3 from '../../assets/PetImages/Matches/dog03.jpg';
import Dog4 from '../../assets/PetImages/Matches/dog04.jpg';
import Dog5 from '../../assets/PetImages/Matches/dog05.jpg';
import Dog6 from '../../assets/PetImages/Matches/dog06.jpg';
import Dog7 from '../../assets/PetImages/Matches/dog07.jpg';
import Dog8 from '../../assets/PetImages/Matches/dog08.jpg';
import Dog9 from '../../assets/PetImages/Matches/dog09.jpg';
import Dog10 from '../../assets/PetImages/Matches/dog10.jpg';
import Dog11 from '../../assets/PetImages/Matches/dog11.jpg';
import Dog12 from '../../assets/PetImages/Matches/dog12.jpg';
import Dog13 from '../../assets/PetImages/Matches/dog13.jpg';
import Dog14 from '../../assets/PetImages/Matches/dog14.jpg';
import Dog15 from '../../assets/PetImages/Matches/dog15.jpg';
import Dog16 from '../../assets/PetImages/Matches/dog16.jpg';
import Dog17 from '../../assets/PetImages/Matches/dog17.jpg';
import Dog18 from '../../assets/PetImages/Matches/dog18.jpg';






export const UserData = [
    {
        id: 1,
        iAm: "Female",
        name: 'Nala',
        age: "2 year",
        breed: "Huski",
        profession: "Software Developer",
        distance: "40km",
        location: "New York, NY",
        avatar: Dog1,
    },
    {
        id: 2,
        name: 'Bentley',
        iAm: "Female",
        age: "3 year",
        breed: "Labrador Retrieve",
        profession: "Software Developer",
        distance: "10km",
        location: "Los Angeles, CA",
        avatar: Dog2,
    },
    {
        id: 3,
        name: 'Ruby',
        iAm: "Female",
        age: "3 year",
        breed: "Poodles",
        profession: "Graphic Designer",
        distance: "25km",
        location: "Chicago, IL",
        avatar: Dog3,
    },
    {
        id: 4,
        name: 'Toby',
        iAm: "Male",
        age: "6 year",
        breed: "French Bulldog",
        profession: "UX Designer",
        distance: "15km",
        location: "Houston, TX",
        avatar: Dog4,
    },
    {
        id: 5,
        iAm: "Male",
        name: 'Zoey',
        age: "4 year",
        breed: "Dalmatian",
        profession: "Data Scientist",
        distance: "18km",
        location: "Phoenix, AZ",
        avatar: Dog5,
    },
    {
        id: 6,
        name: 'Bear',
        iAm: "Male",
        age: "10 year",
        breed: "Basset Hound",
        profession: "Product Manager",
        distance: "22km",
        location: "Philadelphia, PA",
        avatar: Dog6,
    },
    {
        id: 7,
        name: 'Chloe',
        iAm: "Female",
        age: "6 year",
        breed: "Shiba Inu",
        profession: "Frontend Developer",
        distance: "12km",
        location: "San Antonio, TX",
        avatar: Dog7,
    },
    {
        id: 8,
        name: 'Cooper',
        iAm: "Male",
        age: "7 year",
        breed: "Alaskan",
        profession: "Marketing Specialist",
        distance: "35km",
        location: "San Diego, CA",
        avatar: Dog8,
    },
    {
        id: 9,
        name: 'Rosie',
        iAm: "Female",
        age: "7 year",
        breed: "Chihuahua",
        profession: "Software Engineer",
        distance: "28km",
        location: "Dallas, TX",
        avatar: Dog9,
    },
    {
        id: 10,
        name: 'Duke',
        iAm: "Male",
        age: "4 year",
        breed: "Schnauzer",
        profession: "UI Designer",
        distance: "8km",
        location: "San Jose, CA",
        avatar: Dog10,
    },
    {
        id: 11,
        name: 'Sadie',
        iAm: "Male",
        age: "4 year",
        breed: "Bull Terrier",
        profession: "Project Manager",
        distance: "30km",
        location: "Austin, TX",
        avatar: Dog11,
    },
    {
        id: 12,
        name: 'Milo',
        iAm: "Male",
        age: "5 year",
        breed: "German Sheaffer",
        profession: "Content Writer",
        distance: "14km",
        location: "Jacksonville, FL",
        avatar: Dog12,
    },
    {
        id: 13,
        name: 'Daisy',
        iAm: "Female",
        age: "9 year",
        breed: "GreyHound",
        profession: "Full Stack Developer",
        distance: "20km",
        location: "Indianapolis, IN",
        avatar: Dog13,
    },
    {
        id: 14,
        name: 'Charlie',
        iAm: "Male",
        age: "11 year",
        breed: "Samoyed",
        profession: "Business Analyst",
        distance: "32km",
        location: "San Francisco, CA",
        avatar: Dog14,
    },
    {
        id: 15,
        name: 'Luna',
        iAm: "Female",
        age: "6 year",
        breed: "Huski",
        profession: "Network Engineer",
        distance: "11km",
        location: "Columbus, OH",
        avatar: Dog15,
    },
    {
        id: 16,
        name: 'Rocky',
        iAm: "Male",
        age: "7 year",
        breed: "Huski",
        profession: "Digital Marketer",
        distance: "26km",
        location: "Charlotte, NC",
        avatar: Dog16,
    },
    {
        id: 17,
        name: 'Bella',
        iAm: "Female",
        age: "3 year",
        breed: "Huski",
        profession: "UI/UX Designer",
        distance: "19km",
        location: "Fort Worth, TX",
        avatar: Dog17,
    },
    {
        id: 18,
        name: 'Max',
        iAm: "Male",
        age: "8 year",
        breed: "Huski",
        profession: "Mobile App Developer",
        distance: "16km",
        location: "Detroit, MI",
        avatar: Dog18,
    },
    // Add more users as needed
];

