import * as Yup from 'yup';

 const signupSchema = Yup.object({
    petName: Yup.string().min(2).max(25).required("Please Enter Name"),
    petBreed: Yup.string().min(2).max(25).required("Please Enter Breed"),
    petGender: Yup.string().required("Please Enter Gender"),
    size: Yup.string().required("Please Enter Size"),
    // age: Yup.number().required("Please Enter Age"),
    ownerName: Yup.string().min(2).max(25).required("Please Enter Your Name"),
    phone_number: Yup.number().required("Please Enter Phone Number "),
    email: Yup.string().min(2).max(25).required("Please Enter Email"),
    // profession: Yup.string().required("Please Select a Profession"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is required"),
   
});
export default signupSchema