import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allActivities, allUserActivities } from "../api/Activities";



// export const getAllActivies = createAsyncThunk(
//   'Activies/getAllActivies',
//   async () => {
//     try {
//       const response = await allActivities();
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const getAllUserActivies = createAsyncThunk(
  'Activies/getAllUserActivies',
  async () => {
    try {
      const response = await allUserActivities();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const activiesSlice = createSlice({
  name: 'activies',
  initialState: {
    activies: [],
    loading: false,
    error: null,
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getAllActivies.pending, (state) => {
  //       state.loading =true
  //     })
  //     .addCase(getAllActivies.fulfilled, (state,action) => {
  //       state.activies = action.payload
  //       state.loading =false
  //     })
  //     .addCase(getAllActivies.rejected, (state, action) => {
  //     state.loading = true
  //     })
  //     .addCase(getAllUserActivies.pending, (state) => {
  //       state.loading =true
  //     })
  //     .addCase(getAllUserActivies.fulfilled, (state,action) => {
  //       state.activies = action.payload
  //       state.loading =false
  //     })
  //     .addCase(getAllUserActivies.rejected, (state, action) => {
  //     state.loading = true
  //     })
  // },
});

export default activiesSlice.reducer;
