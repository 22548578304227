
export const getKey = () => {
    const datingId = localStorage.getItem("userData");
    const dattingObj = JSON.parse(datingId);
    if (dattingObj?.data?.data?.mode === "65943637acc570d6b14edf38") {
      return "metrimonial";
    } else if (dattingObj?.data?.data?.mode === "65a7a1b743701dd8d36f0d36") {
      return "amora";
    } else {
      return "amora";
    }
  };

  export const Mode = () => {
    const user = localStorage.getItem("userData");
    const users = JSON.parse(user);
    const mode = users?.data?.data?.mode || '659ba5786881dc1bef082ee3';
    return mode;
}

// export mode 
export const modeId = Mode();
export const MODE_DATING = "659ba5786881dc1bef082ee3";


// EXPORT USER FROM LOCAL STORAGE 
export const LOCAL_USER_GENDER = () => {
  const user = localStorage.getItem("userData");
  const users = JSON.parse(user);
  return users?.data?.data?.petGender;
}

// export curuent user
export const CURRENT_LOGIN_USER = () => {
  const user = localStorage.getItem("userData");
  const users = JSON.parse(user);
  return users?.data?.data?._id;
}

export const USER_ID_LOGGEDIN = CURRENT_LOGIN_USER()

  


