import { Component, Fragment } from "react";
import FooterFour from "../../component/layout/footerFour";
import HeaderFour from "../../component/layout/HeaderFour";
// import HeaderTwo from "../component/layout/headertwo";
import PageHeader from "../component/layout/pageheader";

class Policy extends Component {
  render() {
    return (
      <Fragment>
        <HeaderFour />
        {/* <PageHeader
          title={"Privacy & Policy"} curPage={"Policy"}
        /> */}
        <section className="terms-section padding-bottom padding-top">
          <div className="container">
            <div className="terms-content ">
              <div className="terms-header ">
                <h4>Privacy &amp; Policy</h4>
                <p>
                  <span className="theme-color fw-bold">Last Updated:</span>{" "}
                  December 1, 2022
                </p>
              </div>
              <div className="terms-text">
                <p className="mb-4">
                  This Privacy Policy describes how Amora , collects, uses, and
                  protects the personal information you provide when using our
                  dating app. By using Amora, you consent to the practices
                  outlined in this Privacy Policy.
                </p>
                <h4>Information We Collect</h4>
                <ol>
                  <li>
                    <h5>Personal Information</h5>
                    <p>
                      When you create a user account on Amora, we may collect
                      personal information, such as your name, email address,
                      date of birth, gender, and profile picture. You have the
                      option to provide additional information, such as your
                      location, interests, and preferences, to enhance your user
                      experience.
                    </p>
                  </li>
                  <li>
                    <h5>Usage and Log Data</h5>
                    <p>
                      We collect usage information when you access and use
                      Amora, including your device's IP address, browser type,
                      operating system, and the dates and times of your
                      interactions. We may also collect log data, such as your
                      actions within the app, features used, and the content you
                      view or interact with.
                    </p>
                  </li>
                  <li>
                    <h5>Cookies and Similar Technologies</h5>
                    <p>
                      Amora uses cookies and similar technologies to enhance
                      your user experience, personalize content, and analyze app
                      usage. These technologies collect information such as your
                      device type, unique device identifier, and browsing
                      preferences. You can manage your cookie preferences
                      through your device or browser settings.
                    </p>
                  </li>
                </ol>
                <h4>Use of Information</h4>
                <ol>
                  <li>
                    <h5> Personalization and Matchmaking</h5>

                    <p>
                      We use the information you provide to personalize your
                      experience on Amora, including suggesting potential
                      matches based on your preferences and interests. This
                      includes analyzing your profile information, interactions,
                      and other data to improve our matchmaking algorithms.
                    </p>
                  </li>
                  <li>
                    <h5>Communication</h5>

                    <p>
                      We may use your email address to send you notifications,
                      updates, and promotional offers related to Amora. You can
                      opt-out of these communications at any time by adjusting
                      your account settings or using the unsubscribe link
                      provided in the emails.
                    </p>
                  </li>
                  <li>
                    <h5>Data Analysis and Improvements</h5>

                    <p>
                      We analyze the information we collect to understand user
                      behavior, app usage patterns, and preferences. This helps
                      us improve Amora's features, functionality, and overall
                      user experience. We may also use aggregated and anonymized
                      data for research or statistical purposes.
                    </p>
                  </li>
                  <li>
                    <h5>Legal Compliance and Protection</h5>

                    <p>
                      We may disclose your personal information when required by
                      law or to protect our rights, safety, or property, as well
                      as the rights, safety, or property of our users or others.
                      This includes cooperating with law enforcement agencies or
                      complying with legal processes, such as subpoenas or court
                      orders.
                    </p>
                  </li>
                </ol>
                <h5>Data Security</h5>
                <p>
                  We implement reasonable security measures to protect your
                  personal information from unauthorized access, alteration,
                  disclosure, or destruction. However, please be aware that no
                  method of transmission over the internet or electronic storage
                  is completely secure, and we cannot guarantee absolute data
                  security.
                </p>
                <h5>Third-Party Services and Links</h5>
                <p>
                  Amora may contain links to third-party websites or services
                  that are not operated by us. We are not responsible for the
                  privacy practices or content of these third parties. We
                  encourage you to review the privacy policies of those websites
                  or services before providing any personal information.
                </p>
                <h5>Children's Privacy</h5>
                <p>
                  Amora is not intended for individuals under the age of 18. We
                  do not knowingly collect personal information from children.
                  If you believe we have inadvertently collected personal
                  information from a child, please contact us immediately, and
                  we will take appropriate steps to delete the information.
                </p>
                <h5>Changes to the Privacy Policy </h5>
                <p>
                  We reserve the right to modify or update this Privacy Policy
                  at any time. We will notify you of any material changes by
                  posting the updated Privacy Policy on Amora or through other
                  communication channels. Your continued use of Amora after any
                  modifications indicates your acceptance of the updated Privacy
                  Policy.
                </p>
                <h5>Contact Us</h5>
                <p>
                  If you have any questions or concerns regarding this Privacy
                  Policy or our privacy practices, please contact us at (+1)
                  455-4345455.
                </p>
              </div>
            </div>
          </div>
        </section>
        <FooterFour />
      </Fragment>
    );
  }
}

export default Policy;
