import axios from "axios";
import { BASE_URL } from '../../../base';

// export const allActivities = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/pet/activity/all`);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   };

  export const allUserActivities = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/activitys/getByUserId/659ba5786881dc1bef082ee3?page_number=1&page_size=100`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };