import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/layout/scrolltop";
import ErrorPage from "./pages/errorpage";
import LogIn from "./pages/login";
import SignUp from "./pages/signup";
import DatingRoutes from "./dating/routes/dating-routes";
import HomePage from "./pages/home-dating";
import HomePageMetri from "./pages/home-metrimonial";

function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="login" element={<LogIn />} />
          <Route path="register" element={<SignUp />} />
          <Route path="/" element={<HomePage />} />
          <Route path="amora/" element={<HomePage />} />
          <Route path="metrimonial/" element={<HomePageMetri />} />
          <Route path={`/amora/*`} element={<DatingRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

