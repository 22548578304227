import { Component } from "react";
import { Link } from "react-router-dom";
import trust from '../../assets/PetImages/Home/trust&safety.png'
import simple from '../../assets/PetImages/Home/simpleMember.png'

let WorkSectionTwoContentList = [
  {
    imgUrl: trust,
    imgAlt: "Dating Thumb",
    title: "Trust And Safety",
    desc: "Choose from one of our membership level and unlock features you need.",
    btnText: "See More Details",
    btnLink: "/dating/policy",
  },
  {
    imgUrl: simple,
    imgAlt: "Dating Thumb",
    title: "Simple Membership",
    desc: "Choose from one of our membership level and unlock features you need.",
    btnText: "Membership Details",
    btnLink: "/dating/membership",
  },
];

class WorkSectionTwo extends Component {
  render() {
    return (
      <div
        className="work work--style2 padding-top padding-bottom bg_img"
        style={{backgroundColor: "rgb(249, 232, 229)" }}
      >
        <div className="container">
          <div className="section__wrapper">
            <div className="row g-4 justify-content-center">
              {WorkSectionTwoContentList.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-8 col-12 wow fadeInLeft"
                  data-wow-duration="1.5s"
                  key={i}
                >
                  <div className="work__item">
                    <div className="work__inner">
                      <div className="work__thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="work__content">
                        <h3>{val.title}</h3>
                        <p>{val.desc} </p>
                        <Link to={val.btnLink} className="default-btn">
                          <span>{val.btnText}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkSectionTwo;
