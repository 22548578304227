import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterPartnerByAgeAsync } from "../../dating/store/slice/find-partner-Slice";
import toast from "react-hot-toast";
import LoginModal from "../../dating/component/popUps/LoginPls";
import imf from '../../assets/PetImages/Home/banner.png'


const title = "Unleash Love Finding Your Pet's Perfect Match!";
const desc = "The ultimate destination for pet dating! Our platform is dedicated to helping your furry companions find their ideal partners for playdates, walks, and maybe even love.";
const btnText = "Find Match";

// const labelchangeone = "I am a";
// const labelchangetwo = "Looking for";
// const labelchangethree = "Age";
// const labelchangefour = "Cities";
// const btnText = "Find Your Partner";

const imgLink = "../assets/images/banner/01.png";
const imgAlt = "Dating Thumb";

const BannerOne = () => {
  const navigate = useNavigate();
  const isAuthenction = useSelector((state)=>state.userCreate.isAuth)
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

 

  

  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedDistance, setSelectedDistance] = useState(10);
  const [selectAge, setSelectAge] = useState({
    minAge: "20",
    maxAge: "25",
  });

  const startAge = 18;
  const endAge = 40;

  const generateAgeOptions = () => {
    const ageOptions = [];
    for (let age = startAge; age <= endAge; age++) {
      ageOptions.push(
        <option key={age} value={age}>
          {age}
        </option>
      );
    }
    return ageOptions;
  };

  const startAge1 = 19;
  const endAge1 = 40;
  const generateAgeOptionsNew = () => {
    const ageOptions1 = [];
    for (let age = startAge1; age <= endAge1; age++) {
      ageOptions1.push(
        <option key={age} value={age}>                                                                                                                                                  
          {age}
        </option>
      );
    }
    return ageOptions1;
  };

  const handleFilterPartner = async (e) => {
    e.preventDefault(); // Fix the typo here
    if(!isAuthenction){
     
     
      return
    }
    try {
      navigate(`/dating/members?minAge=${selectAge.minAge}&maxAge=${selectAge.maxAge}`);
    } catch (error) {
      console.error("Error filtering partners:", error);
    }
  };

  const handleChangeData = (e) => {
    setSelectAge({ ...selectAge, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleDistanceChange = (e) => {
    setSelectedDistance(e.target.value);
  };
  return (
    <div
      className="banner padding-top padding-bottom bg_img"
      style={{backgroundColor:"#F9E8E5"}}
    >
       <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-0 justify-content-center">
                            <div className="col-lg-6 col-12">
                                <div className="banner__content1 wow fadeInLeft" data-wow-duration="1.5s">
                                    <div className="banner__title">
                                        <h2>{title}</h2>
                                        <p>{desc}</p>
                                        <Link to= {isAuthenction ? "/dating/members" : "/login" } className="default-btn style-2" style={{backgroundColor:'#FF5C2D',color:'white'}}><span>{btnText}</span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 home-banner-cstm">
                                <div className="banner__thumb wow fadeInUp" data-wow-duration="1.5s">
                                    <img src={imf} alt={imgAlt} />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      {/* <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-12">
            <div
              className="banner__content wow fadeInLeft"
              data-wow-duration="1.5s"
            >
              
               <div className="banner__title">
                                        <h2>{title}</h2>
                                        <p>{desc}</p>
                                        <Link to= {isAuthenction ? "/dating/members" : "/login" } className="default-btn style-2" style={{backgroundColor:'#FF5C2D',color:'white'}}><span>{btnText}</span></Link>
                                    </div>
          
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div
              className="banner__thumb banner__thumb--thumb1 wow fadeInUp"
              data-wow-duration="1.5s"
              data-wow-delay="1s"
            >
              <img src={imgLink} alt={imgAlt} />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BannerOne;
