
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dog from "../assets/PetImages/signup/dog.png";
import caat from "../assets/PetImages/signup/caat.png";
import parrot from "../assets/PetImages/signup/parrot.png";
import Amora_logo from "../assets/PetImages/Home/Amora_logo.png";
import { useFormik } from 'formik';
import  sighupSchema  from "../dating/Schema/index"
import Each from "../dating/component/sidebar/Each";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserAsync } from "../dating/store/slice/AuthSlice";
import { useDispatch } from "react-redux";

// import '../Pet_Signup.css';
const title = "Welcome to Amora";
const desc = "Let's create your profile! Just fill in the fields below, and we’ll get a new account.";
const age_array = new Array(18).fill(0).map((_, i) => i + 1)
const animal_enum = {
  0: "dog",
  1: "cat",
  2: "parrot"
}
const animal_list = [
  {
    name: "Dog",
    image: dog
  },
  {
    name: "Cat",
    image: caat
  },
  {
    name: "Parrot",
    image: parrot
  }
]
const initialValues ={
  
    mode: "659ba5786881dc1bef082ee3",
    user: "65a0f645b5672f56454a2b35",
    petName: "",
    petBreed: "",
    petGender: "Male",
    petAge: "3 years",
    petType: "Dog",
    email: "john.doe@example.com",
    password: "securePassword123",
    description: "A friendly and energetic dog.",
    DOB: "2021-01-15",
    interest: [
      "65a660358be96bedb9ca77e3",
      "65a660518be96bedb9ca77ec"
    ],
    subscription: true,
    status: "Active",
    password:'',
    confirmPassword:'',
    ownerName:'',
    phone_number:''
  
}
// const initialValues = {
//   selected_animal: "",
//   dogname: "",
//   breed: "",
//   name: "",
//   email: "",
//   gender: "",
//   size: "",
//   age: "",
//   phone_number: "",
//   profession: ""

// }


const is_valid = (s) => {
  if (!s || s.length === "") {
    return false
  }
  return true
}

const Signup = () => {
  const [section, setSection] = useState(1);
  const dispatch =useDispatch()
 const navigate = useNavigate()
  const { values, touched, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: sighupSchema,
    onSubmit: (values) => {
      console.log('Form submitted successfully', {
        ...values,
        selected_animal: collectData.selected_animal
      });
      const modifydata = {...values,
      selected_animal: collectData.selected_animal}
      console.log(modifydata)
      try {
        
        navigate("/amora/interest");
        dispatch(createUserAsync(modifydata))
      } catch (error) {
        
      }
    }
  })
  const [collectData, setCollectData] = useState({
    selected_animal: ""
  })
  console.log('errors ==', errors)
  const [animalSelectedIndex, setAnimalSelectedIndex] = useState(-1)

  const handleChangeSection = (number) => {

    if (animalSelectedIndex === -1) {

      toast.error("Please select an animal before proceeding.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {

      setSection(number);
    }
  };
  
  const select_animal_handler = (idx) => {
    setAnimalSelectedIndex(idx);
    setCollectData((s) => ({ ...s, selected_animal: animal_enum[idx] }));
    handleChange({ target: { name: 'selected_animal', value: animal_enum[idx] } });
  }

  const is_second_disable = !is_valid(values.petBreed) || !is_valid(values.petName)

  const handleChangeSections = (number) => {
    if (is_second_disable) {

      if (!is_valid(values.petName) && errors.petName) {
        toast.error(errors.petName);
      }
      if (!is_valid(values.petBreed) && errors.petBreed) {
        toast.error(errors.petBreed);
      }
    } else {

      setSection(number);
    }
  };


  return (<>
    <section className="log-reg">
      <div className="top-menu-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-7">
              <div className="logo">
                <Link to="/">
                  <img src={Amora_logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-5">
              <Link
                to="/"
                className="backto-home"
                style={{ color: "#213366" }}
              >
                <i className="fas fa-chevron-left"></i> Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="image"> </div>
          <div className="col-lg-7">
            {section === 1 && (
              <div className="log-reg-inner">
                <div className="section-header">
                  <h2 className="title">{title} </h2>
                  <p>{desc} </p>
                </div>
                <div className="main-content">
                  <h4 className="content-title">Select what animal is it?</h4>
                  {
                    animal_list.map((item, idx) => {
                      return <>
                        <div
                          style={{ background: animalSelectedIndex === idx ? "#FFA238" : "#FFE8CD" }} // css in  assests > fonts > style.css > in last
                          className="pet_box"
                          onClick={() => select_animal_handler(idx)}
                        >
                          <div
                            style={{
                              paddingTop: 15,
                              paddingLeft: 5,
                              paddingRight: 8.73,
                              background: "#FFF3E5",
                              borderRadius: 219.82,
                              overflow: "hidden",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <img style={{ width: 37, height: 36 }} src={item.image} />
                          </div>
                          <div
                            style={{
                              color: "black",
                              fontSize: 18,
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            {item.name}
                          </div>
                        </div></>
                    })
                  }
                </div>
                <div className="text-center">
                  <button
                    // disabled={animalSelectedIndex === -1 ? true : false}
                    className="default-btn reverse"
                    onClick={() => handleChangeSection(2)}
                  >
                    <span>Next</span>
                  </button>
                </div>
                <ToastContainer />
              </div>

            )}
            {section === 2 && (
              <div className="log-reg-inner">
                <div className="section-header">
                  <h2 className="title">{title} </h2>
                  <p>{desc} </p>
                </div>
                <div className="main-content">
                  <form action="#" 
                  onSubmit={handleSubmit}
                  >
                    <h4 className="content-title">
                      Create Profile For Your {collectData.selected_animal}
                    </h4>
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="petName"
                        id="petName"
                        // value={this.state.regName}
                        // onChange={(e)=>{this.setState({regName: e.target.value});}}
                        placeholder={"Enter Your " + collectData.selected_animal + " Name *"}
                        value={values.petName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="my-form-control"
                      />
                      {errors.petName && touched.petName ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.petName}</p>) : null}
                    </div>
                    <div className="form-group">
                      <label>Breed</label>
                      <input
                        type="text"
                        name="petBreed"
                        id="petBreed"
                        required
                      
                        placeholder={"Enter Your " + collectData.selected_animal + " petBreed *"}
                        value={values.petBreed}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="my-form-control"
                      />
                      {errors.petBreed && touched.petBreed ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.petBreed}</p>) : null}
                    </div>
                   
                    <div className="form-group">
                      <label>Gender</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input type="radio"
                            name="petGender"
                            value="male"
                            checked={values.petGender === "male" ? true : false}
                            onChange={handleChange} />
                          <label htmlFor="males1">Male</label>
                        </div>
                        <div className="s-input">
                          <input
                            type="radio"
                            name="petGender"
                            value="female"
                            id="females1"
                            checked={values.petGender === "female" ? true : false}
                            onChange={handleChange} />
                          <label htmlFor="females1">Female</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Size</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input type="radio"
                            value="large"
                            name="size"
                            id="large"
                            onChange={handleChange}
                          />
                          <label htmlFor="large">Large</label>
                        </div>
                        <div className="s-input me-3">
                          <input
                            value="medium"
                            type="radio"
                            name="size"
                            id="medium"
                            onChange={handleChange} />
                          <label htmlFor="medium">Medium</label>
                        </div>
                        <div className="s-input me-3">
                          <input
                            value="small"
                            type="radio"
                            name="size"
                            id="small"
                            onChange={handleChange} />
                          <label htmlFor="small">Small</label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Age</label>
                      <div className="banner__inputlist">

                        <select
                          value={values.petAge}
                          onChange={handleChange}
                          defaultValue="1"
                          name="petAge"
                        >

                          <Each
                            of={age_array}
                            render={(data) => {
                              return <option value={data}>{data}</option>
                            }}
                          />
                        </select>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="default-btn reverse"
                        onClick={() => handleChangeSections(3)}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                    <ToastContainer />

                  </form>
                </div>
              </div>

            )}
            {section === 3 && (
              <div className="log-reg-inner">
                <div className="section-header">
                  <h2 className="title">{title} </h2>
                  <p>{desc} </p>
                </div>
                <div className="main-content">
                  <form onSubmit={handleSubmit}>
                    <h4 className="content-title">Owner Details</h4>
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="ownerName"
                        id="item01"
                   
                        placeholder="Enter Your Name *"
                        className="my-form-control"
                        value={values.ownerName}
                        onChange={handleChange}
                        onBlur={handleBlur}

                      />
                      {errors.ownerName && touched.ownerName ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.ownerName}</p>) : null}
                    </div>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        name="phone_number"
                        id="item01"
                        // value={this.state.regName}
                        // onChange={(e)=>{this.setState({regName: e.target.value});}}
                        placeholder=" Number"
                        className="my-form-control"
                        onChange={handleChange}
                      />
                      {errors.phone_number && touched.phone_number ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.phone_number}</p>) : null}
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        id="item01"
                        // value={this.state.regName}
                        // onChange={(e)=>{this.setState({regName: e.target.value});}}
                        placeholder="Email *"
                        className="my-form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.email}</p>) : null}
                    </div>
                    <div className="form-group">
                        <label>Password*</label>
                        <input
                          type="password"
                          name="password"
                          id="item03"
                          // value={regPassword}
                          // onChange={(e) => setRegPassword(e.target.value)}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Your Password *"
                          className="my-form-control"
                        />
                        {touched.password && errors.password ? (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password*</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          id="item04"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter Your Password *"
                          className="my-form-control"
                        />
                        {touched.confirmPassword &&
                          errors.confirmPassword ? (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>
                    <div className="form-group">
                      <label>Gender</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input type="radio" name="gender" id="males1" onChange={handleChange} />
                          <label htmlFor="males1">Male</label>
                        </div>
                        <div className="s-input">
                          <input type="radio" name="gender" id="females1" onChange={handleChange} />
                          <label htmlFor="females1">Female</label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Profession</label>
                      <div className="banner__inputlist">
                        <select
                          defaultValue="softwer-engineer"
                          onChange={handleChange}
                          value={values.profession}
                          name="profession"
                        >
                          <option value="softwer-engineer">
                            Software Engineer
                          </option>
                          <option value="designer">Graphic designer</option>
                        </select>
                      {errors.profession && touched.profession ? (<p className="form-error" style={{color:"red",fontSize:"12px"}}>{errors.profession}</p>) : null}

                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        style={{ opacity: Object.keys(errors).length ? .7 : 1 }}
                        disabled={Object.keys(errors).length}
                        type="submit"
                        className="default-btn reverse"
                      // onClick={() => handleChangeSections(1)}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                    <ToastContainer />
                    
                  </form>
                </div>
              </div>
            )}
           
          </div>
        </div>
      </div>

    </section>
  </>
  );
};

export default Signup;
