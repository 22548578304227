import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../component/layout/pageheader";
import ActiveMember from "../component/sidebar/member";
import HeaderFour from "../../component/layout/HeaderFour";
import FooterFour from "../../component/layout/footerFour";
import InstallApp from "../component/popUps/installApp";
import BlockUserModal from "../component/popUps/client";
import ShareUserProfileModal from "../component/popUps/ShareUserProfileModal";
import ReportUserModal from "../component/popUps/reportUserModal";
import CheckCompatibilityModal from "../component/popUps/checkCompatibilty"
import RelationshipMilestoneTracker from "../component/popUps/MildStoneModal";
import { UserData } from "../../assets/DummyData/userData";

import Dog1 from '../../assets/PetImages/Matches/dog01.jpg';
import Dog2 from '../../assets/PetImages/Matches/dog02.jpg';
import Dog3 from '../../assets/PetImages/Matches/dog03.jpg';
import Dog4 from '../../assets/PetImages/Matches/dog04.jpg';
import Dog5 from '../../assets/PetImages/Matches/dog05.jpg';
import Dog6 from '../../assets/PetImages/Matches/dog06.jpg';
import Dog7 from '../../assets/PetImages/Matches/dog07.jpg';
import Dog8 from '../../assets/PetImages/Matches/dog08.jpg';
import Dog9 from '../../assets/PetImages/Matches/dog09.jpg';
import Dog10 from '../../assets/PetImages/Matches/dog10.jpg';
import Dog11 from '../../assets/PetImages/Matches/dog11.jpg';
import Dog12 from '../../assets/PetImages/Matches/dog12.jpg';
import Dog13 from '../../assets/PetImages/Matches/dog13.jpg';
import Dog14 from '../../assets/PetImages/Matches/dog14.jpg';
import Dog15 from '../../assets/PetImages/Matches/dog15.jpg';
import Dog16 from '../../assets/PetImages/Matches/dog16.jpg';
import Dog17 from '../../assets/PetImages/Matches/dog17.jpg';
import Dog18 from '../../assets/PetImages/Matches/dog18.jpg';


const name = "William Smith";
const activety = "Active 3 Days Ago";
const desc =
  "Challenges are whats make lifes interesting and overcoming them is what makes life meaningful";



const UserProfile = () => {


  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('userID');
 
  const filteredUser = UserData.filter(user => user.id === parseInt(userId, 10));
 
  const [showInstallApp, setShowInstallApp] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [shareUserModal, setShareUserModal] = useState(false)
  const [CheckCompatibility, setCheckCompatibility] = useState(false)
  const [reportUser, setReportUser] = useState(false)
  const [mildStone, setMildStone] = useState(false)



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowInstallApp(true);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  const interests = [
    "Playing",
    "Eating",
    "Activity",
    "Sleep",
  ]
  return (
    <Fragment>
      <HeaderFour />
      <PageHeader />
      <div className="group group--single padding-bottom">
        <div className="group__top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 d-none d-xl-block"></div>
              <div className="col-xl-9">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="gt1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#gt1"
                      type="button"
                      role="tab"
                      aria-controls="gt1"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-user"></i> About{" "}
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link "
                      id="gt3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#gt3"
                      type="button"
                      role="tab"
                      aria-controls="gt3"
                      aria-selected="false"
                    >
                      <i className="fa-solid fa-photo-film"></i> Media{" "}

                    </button>
                  </li>

                
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                    >
                      <div className="header__more ">
                        <span
                          to='#'
                            className="dropdown-toggle"
                          data-bs-toggle="dropdown">
                           <CheckCompatibilityModal showModal={CheckCompatibility} hideModal={() => setCheckCompatibility(false)} />
                          <Link
                            onClick={() => setCheckCompatibility(true)}
                            style={{position:"relative"}}
                          >
                            <i
                              style={{position:"absolute",
                             zIndex:"2",
                             fontSize:"18px",
                             top:"0px",
                            }}  
                              className="fa fa-question-circle-o"
                              aria-hidden="true"
                              title="Check Compatibility"
                            ></i>
                            <span className="fs-6 ms-4 ps-2 text-muted">Check Compatibilty</span>
                          </Link>
                       </span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"

                    >
                      <div className="header__more">
                        <span
                          to='#'
                          className="pointer dropdown-toggle"
                          style={{
                            fontWeight: "700",
                          }}
                          data-bs-toggle="dropdown">
                          <i class="" aria-hidden="true"></i>{" "}
                          More</span>
                        <ul className="dropdown-menu" aria-labelledby="moreoption" >
                          <li>
                          <ReportUserModal showModal={reportUser} hideModal={() => setReportUser(false)}/>   
                            <Link
                            onClick={() => setReportUser(true)}
                              className="dropdown-item py-2"
                            >
                              <i class="fa fa-flag me-2 " aria-hidden="true"></i>{" "} Report User
                            </Link>
                          </li>
                          <li>
                            {/* Wrap BlockUserModal around the Link component */}
                            <BlockUserModal showModal={showBlockUserModal} hideModal={() => setShowBlockUserModal(false)} />
                            <Link className="dropdown-item py-2" onClick={() => setShowBlockUserModal(true)}>
                              <i className="fa fa-ban me-2" aria-hidden="true"></i> Block User
                            </Link>
                          </li>
                          <li>

                                <Link className="dropdown-item py-2"
                                  onClick={() => setMildStone(true)}
                                >
                                  <i class="fa fa-history me-2" aria-hidden="true"></i>
                                  {" "}
                                  Track MildStone
                                </Link>
                              </li>
                        </ul>
                      </div>
                    </button>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="group__bottom">
          <div className="container">
            <div className="row g-4">
              <div className="col-xl-6 order-xl-1">
                <div className="group__bottom--left">
                  <div className="tab-content" id="myTabContent">
                  <div
                      className="tab-pane fade show active"
                      id="gt1"
                      role="tabpanel"
                      aria-labelledby="gt1-tab"
                    >
                      <div className="info">
                      <div className="info-card mb-4">
                          <div className="info-card-title">
                            <h6>
                              Bio{" "}
                              
                            </h6>

                          </div>

                          
                          <div className="info-card-content">
                           <p>
                           It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                           </p>
                          </div>
                        </div>

                        <div className="info-card mb-4">
                          <div className="info-card-title">
                            <h6>
                              Basic Info{" "}
                              
                            </h6>

                          </div>


                          <div className="info-card-content">
                            <ul className="info-list">
                              <li>
                                <p className="info-name">Name</p>
                                <p className="info-details">
                                {filteredUser[0]?.name}
                                </p>
                              </li>
                              <li>
                                <p className="info-name">I'm a</p>
                                <p className="info-details">
                                {filteredUser[0]?.iAm}
                                  {/* {User?.iAm === "Male" ? "Man" : "Woman" || ""} */} 
                                </p>
                              </li>
                              {/* <li>
                                <p className="info-name">Loking for a</p>
                                <p className="info-details">
                                  {User?.looking === "Female" ? "Woman" : "Man"}Woman
                                </p>
                              </li> */}
                              <li>
                                <p className="info-name">Marital Status</p>
                                <p className="info-details">
                                  Single
                                </p>
                              </li>

                              <li>
                                <p className="info-name">Age</p>
                                <p className="info-details">
                                {filteredUser[0]?.age}
                                </p>
                              </li>
                              <li>
                                <p className="info-name">Breed</p>
                                <p className="info-details">
                                {filteredUser[0]?.breed}
                                </p>
                              </li>
                              <li>
                                <p className="info-name">Address</p>
                                <p className="info-details">
                                {filteredUser[0]?.location}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                            {/* <div className="info-card">
                          <div className="info-card-title">
                            <h6>
                             Contact Details{" "}
                              
                            </h6>
                          </div>
                          <div className="info-card-content">
                            <ul className="info-list">
                              <li>
                                <p className="info-name">Email</p>
                                <p className="info-details">
                                johnsmith@gmail.com
                                </p>
                              </li>
                             
                              <li>
                                <p className="info-name">Phone</p>
                                <p className="info-details">
                                +123 --------345
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        

                     
                      </div>
                    </div>

                    <div
                      className="tab-pane fade show "
                      id="gt3"
                      role="tabpanel"
                      aria-labelledby="gt3-tab"
                    >
                      <div className="group__bottom--body bg-white">
                        <div className="group__bottom--allmedia">
                          <div className="media-wrapper">
                            <ul
                              className="nav nav-tabs"
                              id="myTab3"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="all-media-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#all-media"
                                  type="button"
                                  role="tab"
                                  aria-controls="all-media"
                                  aria-selected="true"
                                >
                                  <i className="fa-solid fa-table-cells-large"></i>{" "}
                                  All <span>12</span>
                                </button>
                              </li>

                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="photos-media-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#photos-media"
                                  type="button"
                                  role="tab"
                                  aria-controls="photos-media"
                                  aria-selected="false"
                                >
                                  <i className="fa-solid fa-image"></i> Photos{" "}
                                  <span>4</span>
                                </button>
                              </li>

                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="video-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#video"
                                  type="button"
                                  role="tab"
                                  aria-controls="video"
                                  aria-selected="false"
                                >
                                  <i className="fa-solid fa-video"></i> Videos{" "}
                                  <span>4</span>
                                </button>
                              </li>
                            </ul>

                            <div className="tab-content" id="myTabContent3">
                              <div
                                className="tab-pane fade show active"
                                id="all-media"
                                role="tabpanel"
                                aria-labelledby="all-media-tab"
                              >
                                <div className="media-content">
                                  <ul className="media-upload">
                                    <li className="upload-now">
                                      {/* <div className="custom-upload">
                                        <div className="file-btn">
                                          <i className="fa-solid fa-upload"></i>{" "}
                                          Upload
                                        </div>
                                        <input type="file" />
                                      </div> */}
                                    </li>
                                  </ul>
                                  <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog1}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog1}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb albam-thumb">
                                        <img
                                          src={Dog3}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog3}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-camera"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog4}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog4}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog5}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog5}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog6}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog6}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb albam-thumb">
                                        <img
                                          src={Dog7}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog7}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-camera"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog8}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog8}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog9}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog9}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog11}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog11}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb albam-thumb">
                                        <img
                                          src={Dog15}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog15}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-camera"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog13}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog13}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb albam-thumb">
                                        <img
                                          src={Dog16}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog16}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-camera"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center mt-5">
                                    <a href="#" className="default-btn">
                                      <i className="fa-solid fa-spinner"></i>{" "}
                                      Load More
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="tab-pane fade"
                                id="photos-media"
                                role="tabpanel"
                                aria-labelledby="photos-media-tab"
                              >
                                <div className="media-content">
                                  <ul className="media-upload">
                                    <li className="upload-now">
                                      <div className="custom-upload">
                                        <div className="file-btn">
                                          <i className="fa-solid fa-upload"></i>{" "}
                                          Upload
                                        </div>
                                        <input type="file" />
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog4}
                                          alt="dating thumb"
                                        />
                                        <a
                                        
                                          href={Dog4}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog5}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog5}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog6}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog6}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb">
                                        <img
                                          src={Dog8}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog8}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-image"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-center mt-5">
                                    <a href="#" className="default-btn">
                                      <i className="fa-solid fa-spinner"></i>{" "}
                                      Load More
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="tab-pane fade"
                                id="video"
                                role="tabpanel"
                                aria-labelledby="video-tab"
                              >
                                <div className="media-content">
                                  <ul className="media-upload">
                                    <li className="upload-now">
                                      <div className="custom-upload">
                                        <div className="file-btn">
                                          <i className="fa-solid fa-upload"></i>{" "}
                                          Upload
                                        </div>
                                        <input type="file" />
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-3 g-3">
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog3}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog3}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog6}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog6}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="../fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog8}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog8}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="media-thumb video-thumb">
                                        <img
                                          src={Dog12}
                                          alt="dating thumb"
                                        />
                                        <a
                                          href={Dog12}
                                          target="_blank"
                                          className="icon"
                                        >
                                          <i className="fa-solid fa-circle-play"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="text-center mt-5">
                                    <a href="#" className="default-btn">
                                      <i className="fa-solid fa-spinner"></i>{" "}
                                      Load More
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 order-xl-0">
                <div className="group__bottom--center">
                  <div className="story__item style2">
                    <div className="story__inner">
                      <div className="story__thumb position-relative">
                        <img
                          src={filteredUser[0]?.avatar}
                          alt="dating thumb"
                        />
                        
                      </div>
                      <div className="story__content px-0 pb-0">
                        <h4>{filteredUser[0]?.name}</h4>
                        <div className="story__content--content mb-2 pb-3">
                          <p>
                            <i className="fa-solid fa-clock"></i> {activety}
                          </p>
                        </div>
                       
                        <div className="container">
                          <h4>Interests</h4>
                          <div className="row">
                            {interests.map((interest, index) => (
                              <div
                                key={index}
                                style={{
                                  margin: "10px 10px 10px 10px",
                                  background: "rgb(255, 92, 45)",
                                  color: "#fff",
                                  padding: "5px 12px",
                                  borderRadius: "25px",
                                }}
                                className={`interest-item col text-center  flex-nowrap ${selectedInterests.includes(interest) ? "selected" : ""}`}
                              >
                                {interest}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 order-xl-2">
                <div className="group__bottom--right">
                  <ActiveMember />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showInstallApp && <InstallApp />}
      <FooterFour />
      <RelationshipMilestoneTracker
       showModal={mildStone} 
       hideModal={() => setMildStone(false)} 
       />
    </Fragment>
  );
};

export default UserProfile;
