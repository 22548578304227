import { Component } from "react";
import { Link } from "react-router-dom";
// images for female--------------------------


import img02 from '../../assets/images/member/home2/project-pic-female/1.png';
import img03 from '../../assets/images/member/home2/project-pic-female/2.png';
import img04 from '../../assets/images/member/home2/project-pic-female/3.png';
import img05 from '../../assets/images/member/home2/project-pic-female/4.png';
import img06 from '../../assets/images/member/home2/project-pic-female/5.png';
import img07 from '../../assets/images/member/home2/project-pic-female/6.png';
import img08 from '../../assets/images/member/home2/project-pic-female/7.png';
import img09 from '../../assets/images/member/home2/project-pic-female/8.png';
import img010 from '../../assets/images/member/home2/project-pic-female/9.png';

import Dog1 from '../../assets/PetImages/Matches/dog01.jpg';
import Dog2 from '../../assets/PetImages/Matches/dog02.jpg';
import Dog3 from '../../assets/PetImages/Matches/dog03.jpg';
import Dog4 from '../../assets/PetImages/Matches/dog04.jpg';
import Dog5 from '../../assets/PetImages/Matches/dog05.jpg';
import Dog6 from '../../assets/PetImages/Matches/dog06.jpg';
import Dog7 from '../../assets/PetImages/Matches/dog07.jpg';
import Dog8 from '../../assets/PetImages/Matches/dog08.jpg';
import Dog9 from '../../assets/PetImages/Matches/dog09.jpg';
import Dog10 from '../../assets/PetImages/Matches/dog10.jpg';
import Dog11 from '../../assets/PetImages/Matches/dog11.jpg';
import Dog12 from '../../assets/PetImages/Matches/dog12.jpg';
import Dog13 from '../../assets/PetImages/Matches/dog13.jpg';
import Dog14 from '../../assets/PetImages/Matches/dog14.jpg';
import Dog15 from '../../assets/PetImages/Matches/dog15.jpg';
import Dog16 from '../../assets/PetImages/Matches/dog16.jpg';
import Dog17 from '../../assets/PetImages/Matches/dog17.jpg';
import Dog18 from '../../assets/PetImages/Matches/dog18.jpg';

const title = "Members";

let ActiveMemberListOne = [
  {
    imgUrl: Dog11,
    imgAlt: "Dating Thumb",
    title: "Toby",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog6,
    imgAlt: "Dating Thumb",
    title: "Bentley",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog3,
    imgAlt: "Dating Thumb",
    title: "Ruby",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog4,
    imgAlt: "Dating Thumb",
    title: "Zoey",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog8,
    imgAlt: "Dating Thumb",
    title: "Chloe",
    activety: "2 years ago",
  },
];

let ActiveMemberListTwo = [
  {
    imgUrl: Dog9,
    imgAlt: "Dating Thumb",
    title: "Rosie",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog5,
    imgAlt: "James Anderson",
    title: "Sadie",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog1,
    imgAlt: "Dating Thumb",
    title: "Daisy",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog2,
    imgAlt: "Dating Thumb",
    title: "Luna",
    activety: "2 years ago",
  },
  {
    imgUrl: Dog10,
    imgAlt: "Dating Thumb",
    title: "Bella",
    activety: "2 years ago",
  },
];

class ActiveMember extends Component {
  render() {
    return (
      <div className="active-member bg-white pt-4 mb-4">
        <div className="modal-header">
          <h6>{title}</h6>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Newest-tab"
                data-bs-toggle="tab"
                data-bs-target="#Newest"
                type="button"
                role="tab"
                aria-controls="Newest"
                aria-selected="true"
              >
                Newest{" "}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Popular-tab"
                data-bs-toggle="tab"
                data-bs-target="#Popular"
                type="button"
                role="tab"
                aria-controls="Popular"
                aria-selected="false"
              >
                Popular
              </button>
            </li>
          </ul>
        </div>
        <div className="modal-body group__bottom--body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="Newest"
              role="tabpanel"
              aria-labelledby="Newest-tab"
            >
              <ul>
                {ActiveMemberListOne.map((val, i) => (
                  <li key={i}>
                    <div className="top">
                      <div className="left member--style2">
                        <div className="member__thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          <span className="member__activity"></span>
                        </div>
                      </div>
                      <div className="right">
                        <Link to="/amora/match-page">
                          <h6>{val.title}</h6>
                        </Link>
                        <span>{val.activety}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="Popular"
              role="tabpanel"
              aria-labelledby="Popular-tab"
            >
              <ul>
                {ActiveMemberListTwo.map((val, i) => (
                  <li key={i}>
                    <div className="top">
                      <div className="left member--style2">
                        <div className="member__thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          <span className="member__activity"></span>
                        </div>
                      </div>
                      <div className="right">
                        <Link to="/dating/match-page">
                          <h6>{val.title}</h6>
                        </Link>
                        <span>{val.activety}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActiveMember;
