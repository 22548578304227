import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateUserProfileAsync } from "../../store/slice/profileSlice";
import moment from 'moment';



const ManageProfileBasicInput = ({ userData, onUpdateProfile }) => {
  const [userDataEdit, setUserDataEdit] = useState({
    petName: userData?.petName || "",
    // dob:  moment(userData?.dob).format("YYYY-MM-DD") || '',
    petGender: userData?.petGender || "",
    looking: userData?.looking || "",
    petType: userData?.petType || "",
    petBreed: userData?.petBreed || "",
    petColor: userData?.petColor || "",
    petAge: userData?.petAge || "",
    petWeight: userData?.petWeight || "",
    marital: userData?.marital || "",
    Height: userData?.Height || "",
    address: userData?.address || "",
    interests: userData?.interests ||"",
  });

  const [buttonClass, setButtonClass] = useState("default-btn reverse");
  const dispatch = useDispatch();
  const userDatas = localStorage.getItem("userData");
  const userDataObj = JSON.parse(userDatas);
  const userId = userDataObj?.data?.data?._id || null;

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      setUserDataEdit((prevUserDataEdit) => ({
        ...prevUserDataEdit,
        [name]: value,
      }));
    } else {
      setUserDataEdit((prevUserDataEdit) => ({
        ...prevUserDataEdit,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUserData = userDataEdit;
      await dispatch(updateUserProfileAsync({ updatedUserData, userId }));
  
      onUpdateProfile();
      toast.success("Basic info successfully updated");
      setButtonClass("default-btn reverse");
    } catch (error) {
      console.error("Error updating user profile:", error);
      toast.error("Failed to update basic info");
    }
  };

  return (
    <section className="log-reg log-reg-manage-profile">
      <div className="container">
        <div className="row manage-profile-input-bg">
          <div className="col-lg-12 ps-lg-5">
            <div className="log-reg-inner  input-height-basic">
              <div className="main-content">
                <>
                  <form onSubmit={handleSubmit}>
                    <h4 className="content-title manage-profile-input-top-title">
                      Basic info
                    </h4>
                  
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="petName"
                        value= {userDataEdit.petName}
                        onChange={handleChange}
                        className="my-form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Breed*</label>
                      <input
                        type="text"
                        name="petBreed"
                        value= {userDataEdit.petBreed}
                        onChange={handleChange}
                        className="my-form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Age*</label>
                      <input
                        type="text"
                        name="petAge"
                        value= {userDataEdit.petAge}
                        onChange={handleChange}
                        className="my-form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Gender*</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input
                            type="radio"
                            name="petGender"
                            id="petGenderMale"
                            value="male"
                            checked={userDataEdit.petGender === "male"}
                            onChange={handleChange}
                          />
                          <label htmlFor="Male">Male</label>
                        </div>
                        <div className="s-input">
                          <input
                            type="radio"
                            name="petGender"
                            id="petGenderFemale"
                            value="female"
                            checked={userDataEdit.petGender === "female"}
                            onChange={handleChange}
                          />
                          <label htmlFor="Female">Female</label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label>Looking for a*</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input
                            type="radio"
                            name="looking"
                            id="lookingForMale"
                            value="Male"
                            checked={userDataEdit.looking === "Male"}
                            onChange={handleChange}
                          />
                          <label htmlFor="lookingForMale">Male</label>
                        </div>
                        <div className="s-input">
                          <input
                            type="radio"
                            name="looking"
                            id="lookingForFemale"
                            value="Female"
                            checked={userDataEdit.looking === "Female"}
                            onChange={handleChange}
                          />
                          <label htmlFor="lookingForFemale">Female</label>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group">
                      <label>Relationship Status*</label>
                      <div className="banner__inputlist">
                        <div className="s-input me-3">
                          <input
                            className="pointer"
                            type="radio"
                            name="marital"
                            id="Single"
                            value="Single"
                            checked={userDataEdit.marital === "Single"}
                            onChange={handleChange}
                          />
                          <label className="pointer" htmlFor="Single">
                            Single
                          </label>
                        </div>
                        <div className="s-input me-3">
                          <input
                            className="pointer"
                            type="radio"
                            name="marital"
                            id="married"
                            value="Married"
                            checked={userDataEdit.marital === "Married"}
                            onChange={handleChange}
                          />
                          <label className="pointer" htmlFor="married">
                            Married
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <label>Weight*</label>
                      <input
                        type="text"
                        name="petWeight"
                        className="my-form-control"
                        placeholder="Enter Your Weight"
                        value={userDataEdit.petWeight}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Pet*</label>
                      <input
                        type="text"
                        name="petType"
                        value= {userDataEdit.petType}
                        onChange={handleChange}
                        className="my-form-control"
                      />
                    </div>
                    <button
                      type="submit"
                      className={buttonClass}
                      onClick={() =>
                        setButtonClass("info basic-info-manage-ptofile")
                      }
                    >
                      <span>Update profile</span>
                    </button>
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageProfileBasicInput;
